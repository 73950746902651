var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "e-filters",
        {
          staticClass: "border rounded border-gray pb-0 mb-0",
          attrs: { title: _vm.$t("Filtros"), searching: _vm.fetching },
          on: { search: _vm.submitFilter, reset: _vm.resetHistoryFiltersLocal },
        },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { md: "2" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "history-sale_id",
                      type: "number",
                      label: _vm.$t("Id venda"),
                    },
                    model: {
                      value: _vm.historyFilters.saleId,
                      callback: function ($$v) {
                        _vm.$set(_vm.historyFilters, "saleId", $$v)
                      },
                      expression: "historyFilters.saleId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "4" } },
                [
                  _c("e-store-combo", {
                    attrs: { id: "history-storeId", required: false },
                    model: {
                      value: _vm.historyFilters.storeId,
                      callback: function ($$v) {
                        _vm.$set(_vm.historyFilters, "storeId", $$v)
                      },
                      expression: "historyFilters.storeId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "4" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "history-status",
                      type: "vue-select",
                      label: _vm.$t("Origem da venda"),
                      placeholder: _vm.$t("Todos"),
                      options: _vm.saleOriginOptions(),
                    },
                    model: {
                      value: _vm.historyFilters.origin,
                      callback: function ($$v) {
                        _vm.$set(_vm.historyFilters, "origin", $$v)
                      },
                      expression: "historyFilters.origin",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "2" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "history-status",
                      type: "vue-select",
                      label: _vm.$t("Status Venda"),
                      placeholder: _vm.$t("Todos"),
                      options: _vm.saleStatusOptions(),
                    },
                    model: {
                      value: _vm.historyFilters.status,
                      callback: function ($$v) {
                        _vm.$set(_vm.historyFilters, "status", $$v)
                      },
                      expression: "historyFilters.status",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "2" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "history-delivery_status",
                      type: "vue-select",
                      label: _vm.$t("Status Entrega"),
                      placeholder: _vm.$t("Todos"),
                      options: _vm.deliveryStatusOptions(),
                    },
                    model: {
                      value: _vm.historyFilters.deliveryStatus,
                      callback: function ($$v) {
                        _vm.$set(_vm.historyFilters, "deliveryStatus", $$v)
                      },
                      expression: "historyFilters.deliveryStatus",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "2" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "history-is_delivery",
                      type: "vue-select",
                      label: _vm.$t("Delivery"),
                      placeholder: _vm.$t("Todos"),
                      options: _vm.yesNoTypes(),
                    },
                    model: {
                      value: _vm.historyFilters.isDelivery,
                      callback: function ($$v) {
                        _vm.$set(_vm.historyFilters, "isDelivery", $$v)
                      },
                      expression: "historyFilters.isDelivery",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "2" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "history-is_consigned",
                      type: "vue-select",
                      label: _vm.$t("Consignado"),
                      placeholder: _vm.$t("Todos"),
                      options: _vm.yesNoTypes(),
                    },
                    model: {
                      value: _vm.historyFilters.isConsigned,
                      callback: function ($$v) {
                        _vm.$set(_vm.historyFilters, "isConsigned", $$v)
                      },
                      expression: "historyFilters.isConsigned",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "4" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "history-issue_date",
                      type: "date-range-picker",
                      label: _vm.$t("Período de emissão"),
                      "time-picker": false,
                    },
                    model: {
                      value: _vm.historyFilters.issueDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.historyFilters, "issueDate", $$v)
                      },
                      expression: "historyFilters.issueDate",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "pt-1" },
        [
          _c(
            "b-col",
            [
              _c(
                "div",
                { staticClass: "mb-2" },
                [
                  _c("e-page-size-selector", {
                    attrs: { "per-page": _vm.paging.pageSize },
                    on: { change: _vm.pageSizeChange },
                  }),
                ],
                1
              ),
              _c("e-table-nested-rows", {
                attrs: {
                  fields: _vm.fieldsNestedTable,
                  items: _vm.clientHistory,
                  "nested-list-property": "items",
                  "empty-text": _vm.$t(
                    "Nenhuma venda encontrada para os filtros selecionados"
                  ),
                  fetching: _vm.fetching,
                },
                scopedSlots: _vm._u([
                  {
                    key: "detail-cell(product)",
                    fn: function (ref) {
                      var item = ref.item
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("Compra") +
                                ": " +
                                item.id +
                                " ( " +
                                item.items.length +
                                " )"
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                  {
                    key: "nested-cell(product)",
                    fn: function (ref) {
                      var itemNested = ref.itemNested
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "d-flex align-items-center",
                            staticStyle: { gap: "10px" },
                          },
                          [
                            _c(
                              "div",
                              { staticStyle: { width: "max-width: 70px" } },
                              [
                                _c("b-img-lazy", {
                                  staticStyle: {
                                    "object-fit": "contain",
                                    "max-height": "70px",
                                    "max-width": "70px",
                                  },
                                  attrs: {
                                    src:
                                      itemNested.sku.product.imageSquare ||
                                      _vm.noImage,
                                    rounded: "",
                                    center: "",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("p", { staticClass: "mb-0" }, [
                              _vm._v(
                                " " + _vm._s(itemNested.sku.product.name) + " "
                              ),
                            ]),
                          ]
                        ),
                      ]
                    },
                  },
                  {
                    key: "detail-cell(quantity)",
                    fn: function (ref) {
                      var item = ref.item
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("Loja") + ": " + item.store.name) +
                            " "
                        ),
                      ]
                    },
                  },
                  {
                    key: "detail-cell(unitValue)",
                    fn: function (ref) {
                      var item = ref.item
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("Data Compra") + ":") +
                            " " +
                            _vm._s(_vm._f("datetime")(item.issueDate)) +
                            " "
                        ),
                      ]
                    },
                  },
                  {
                    key: "detail-cell(netValue)",
                    fn: function (ref) {
                      var item = ref.item
                      return [
                        _vm._v(
                          " " + _vm._s(_vm._f("currency")(item.netValue)) + " "
                        ),
                      ]
                    },
                  },
                  {
                    key: "custom-foot",
                    fn: function () {
                      return [
                        _c("tr", [
                          _c(
                            "th",
                            {
                              staticClass: "text-right",
                              attrs: { colspan: "3" },
                            },
                            [_vm._v(" Total ")]
                          ),
                          _c("th", { staticClass: "text-right" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm._f("currency")(_vm.netValue)) +
                                " "
                            ),
                          ]),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { cols: "6" } },
            [
              _c("e-pagination-summary", {
                attrs: {
                  "current-page": _vm.paging.currentPage,
                  "per-page": _vm.paging.pageSize,
                  total: _vm.paging.rowCount,
                  "total-on-page": _vm.paging.rowsInCurrentPage,
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "6" } },
            [
              _c("b-pagination", {
                attrs: {
                  align: "right",
                  "total-rows": _vm.paging.rowCount,
                  "per-page": _vm.paging.pageSize,
                  "aria-controls": "clientHistory-table",
                },
                on: { change: _vm.pageChange },
                model: {
                  value: _vm.paging.currentPage,
                  callback: function ($$v) {
                    _vm.$set(_vm.paging, "currentPage", $$v)
                  },
                  expression: "paging.currentPage",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }