var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("e-register-form", {
    ref: "customerForm",
    attrs: {
      "person-form": _vm.person,
      "is-edit": _vm.isEdit,
      "is-read-only": _vm.isReadOnly,
      "is-customer": "",
    },
    on: {
      "clean-person-data": function ($event) {
        return _vm.$emit("clean-person-data")
      },
    },
    scopedSlots: _vm._u([
      {
        key: "others-inputs",
        fn: function () {
          return [
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  { attrs: { md: "6" } },
                  [
                    !_vm.isReadOnly &&
                    _vm.$can("UpdateCustomerClassification", "Customer")
                      ? _c("FormulateInput", {
                          staticClass: "required",
                          attrs: {
                            id: "customer-classification",
                            label: _vm.$t("Classificação"),
                            placeholder: _vm.$t("Selecione"),
                            validation: "required",
                            type: "vue-select",
                            clearable: false,
                            options: _vm.customerClassificationsOptions,
                          },
                          model: {
                            value: _vm.person.customerClassificationId,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.person,
                                "customerClassificationId",
                                $$v
                              )
                            },
                            expression: "person.customerClassificationId",
                          },
                        })
                      : _c("FormulateInput", {
                          attrs: {
                            id: "customer-classification",
                            label: _vm.$t("Classificação"),
                            type: "label",
                            options: _vm.customerClassificationsOptions,
                          },
                          model: {
                            value: _vm.person.customerClassificationId,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.person,
                                "customerClassificationId",
                                $$v
                              )
                            },
                            expression: "person.customerClassificationId",
                          },
                        }),
                  ],
                  1
                ),
                _c(
                  "b-col",
                  { attrs: { md: "6" } },
                  [
                    !_vm.isReadOnly && _vm.$can("UpdatePriceTable", "Customer")
                      ? _c("FormulateInput", {
                          attrs: {
                            id: "price-table",
                            label: _vm.$t("Tabela de preços"),
                            placeholder: _vm.$t("Selecione"),
                            type: "select-with-button",
                            options: _vm.getComboPriceTables,
                            "button-permission": _vm.$can(
                              "Create",
                              "PriceTable"
                            ),
                          },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("show-price-table")
                            },
                          },
                          model: {
                            value: _vm.person.priceTableId,
                            callback: function ($$v) {
                              _vm.$set(_vm.person, "priceTableId", $$v)
                            },
                            expression: "person.priceTableId",
                          },
                        })
                      : _c("FormulateInput", {
                          attrs: {
                            id: "price-table",
                            label: _vm.$t("Tabela de preços"),
                            type: "label",
                            options: _vm.getComboPriceTables,
                          },
                          model: {
                            value: _vm.person.priceTableId,
                            callback: function ($$v) {
                              _vm.$set(_vm.person, "priceTableId", $$v)
                            },
                            expression: "person.priceTableId",
                          },
                        }),
                  ],
                  1
                ),
                _c(
                  "b-col",
                  { attrs: { md: "12" } },
                  [
                    _vm.isReadOnly
                      ? _c("FormulateInput", {
                          attrs: {
                            id: "observation",
                            type: "label",
                            label: _vm.$t("Observação"),
                          },
                          model: {
                            value: _vm.person.observation,
                            callback: function ($$v) {
                              _vm.$set(_vm.person, "observation", $$v)
                            },
                            expression: "person.observation",
                          },
                        })
                      : _c("FormulateInput", {
                          attrs: {
                            id: "observation",
                            type: "textarea",
                            label: _vm.$t("Observação"),
                          },
                          model: {
                            value: _vm.person.observation,
                            callback: function ($$v) {
                              _vm.$set(_vm.person, "observation", $$v)
                            },
                            expression: "person.observation",
                          },
                        }),
                  ],
                  1
                ),
                _c(
                  "b-col",
                  { attrs: { md: "4" } },
                  [
                    _vm.isReadOnly
                      ? _c(
                          "div",
                          [
                            _c("p", { staticClass: "h6" }, [
                              _vm._v(
                                " " + _vm._s(_vm.$t("Mensagem Push?")) + " "
                              ),
                            ]),
                            _c("e-status-badge", {
                              staticStyle: { "max-width": "80px" },
                              attrs: {
                                status: !!_vm.person.pushNotification,
                                type: "yesNo",
                              },
                            }),
                          ],
                          1
                        )
                      : _c("FormulateInput", {
                          attrs: {
                            id: "customer_form-push_message",
                            type: "switch",
                            label: _vm.$t("Mensagem Push?"),
                          },
                          model: {
                            value: _vm.person.pushNotification,
                            callback: function ($$v) {
                              _vm.$set(_vm.person, "pushNotification", $$v)
                            },
                            expression: "person.pushNotification",
                          },
                        }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }