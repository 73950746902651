<template>
  <div>
    <e-filters
      :title="$t('Filtros')"
      class="border rounded border-gray pb-0 mb-0"
      :searching="fetching"
      @search="submitFilter"
      @reset="resetHistoryFiltersLocal"
    >
      <b-row>
        <b-col md="2">
          <FormulateInput
            id="history-sale_id"
            v-model="historyFilters.saleId"
            type="number"
            :label="$t('Id venda')"
          />
        </b-col>
        <b-col md="4">
          <e-store-combo
            id="history-storeId"
            v-model="historyFilters.storeId"
            :required="false"
          />
        </b-col>
        <b-col md="4">
          <FormulateInput
            id="history-status"
            v-model="historyFilters.origin"
            type="vue-select"
            :label="$t('Origem da venda')"
            :placeholder="$t('Todos')"
            :options="saleOriginOptions()"
          />
        </b-col>
        <b-col md="2">
          <FormulateInput
            id="history-status"
            v-model="historyFilters.status"
            type="vue-select"
            :label="$t('Status Venda')"
            :placeholder="$t('Todos')"
            :options="saleStatusOptions()"
          />
        </b-col>
        <b-col md="2">
          <FormulateInput
            id="history-delivery_status"
            v-model="historyFilters.deliveryStatus"
            type="vue-select"
            :label="$t('Status Entrega')"
            :placeholder="$t('Todos')"
            :options="deliveryStatusOptions()"
          />
        </b-col>

        <b-col md="2">
          <FormulateInput
            id="history-is_delivery"
            v-model="historyFilters.isDelivery"
            type="vue-select"
            :label="$t('Delivery')"
            :placeholder="$t('Todos')"
            :options="yesNoTypes()"
          />
        </b-col>
        <b-col md="2">
          <FormulateInput
            id="history-is_consigned"
            v-model="historyFilters.isConsigned"
            type="vue-select"
            :label="$t('Consignado')"
            :placeholder="$t('Todos')"
            :options="yesNoTypes()"
          />
        </b-col>
        <b-col md="4">
          <FormulateInput
            id="history-issue_date"
            v-model="historyFilters.issueDate"
            type="date-range-picker"
            :label="$t('Período de emissão')"
            :time-picker="false"
          />
        </b-col>
      </b-row>
    </e-filters>

    <b-row class="pt-1">
      <b-col>
        <div class="mb-2">
          <e-page-size-selector
            :per-page="paging.pageSize"
            @change="pageSizeChange"
          />
        </div>

        <e-table-nested-rows
          :fields="fieldsNestedTable"
          :items="clientHistory"
          nested-list-property="items"
          :empty-text="$t('Nenhuma venda encontrada para os filtros selecionados')"
          :fetching="fetching"
        >
          <template #detail-cell(product)="{ item }">
            {{ `${$t('Compra')}: ${item.id} ( ${item.items.length} )` }}
          </template>
          <template #nested-cell(product)="{ itemNested }">
            <div
              class="d-flex align-items-center"
              style="gap: 10px"
            >
              <div style="width: max-width: 70px ">
                <b-img-lazy
                  :src="itemNested.sku.product.imageSquare || noImage"
                  rounded
                  center
                  style="object-fit: contain; max-height: 70px; max-width: 70px"
                />
              </div>
              <p class="mb-0">
                {{ itemNested.sku.product.name }}
              </p>
            </div>
          </template>

          <template #detail-cell(quantity)="{ item }">
            {{ `${$t('Loja')}: ${item.store.name}` }}
          </template>

          <template #detail-cell(unitValue)="{ item }">
            {{ `${$t('Data Compra')}:` }} {{ item.issueDate | datetime }}
          </template>

          <template #detail-cell(netValue)="{ item }">
            {{ item.netValue | currency }}
          </template>

          <template #custom-foot>
            <tr>
              <th
                colspan="3"
                class="text-right"
              >
                Total
              </th>
              <th class="text-right">
                {{ netValue | currency }}
              </th>
            </tr>
          </template>
        </e-table-nested-rows>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="6">
        <e-pagination-summary
          :current-page="paging.currentPage"
          :per-page="paging.pageSize"
          :total="paging.rowCount"
          :total-on-page="paging.rowsInCurrentPage"
        />
      </b-col>
      <b-col cols="6">
        <b-pagination
          v-model="paging.currentPage"
          align="right"
          :total-rows="paging.rowCount"
          :per-page="paging.pageSize"
          aria-controls="clientHistory-table"
          @change="pageChange"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BPagination, BImgLazy } from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'
import { saleDomain, statusTypes } from '@/mixins'
import EPageSizeSelector from '@/views/components/pagination/EPageSizeSelector.vue'
import EPaginationSummary from '@/views/components/pagination/EPaginationSummary.vue'
import EFilters from '@/views/components/EFilters.vue'
import EStoreCombo from '@/views/components/inputs/EStoreCombo.vue'
import NoImage from '@/assets/images/noimage.png'
import ETableNestedRows from '@/views/components/tables/ETableNestedRows.vue'

export default {
  name: 'HistoryTab',
  components: {
    BCol,
    BRow,
    BPagination,
    BImgLazy,
    EPageSizeSelector,
    EPaginationSummary,
    EFilters,
    EStoreCombo,
    ETableNestedRows,
  },

  mixins: [statusTypes, saleDomain],

  props: {
    customerId: {
      type: Number,
      required: false,
      default: 0,
    },
  },

  data() {
    return {
      fetching: false,
      noImage: NoImage,
    }
  },

  computed: {
    ...mapState('pages/sale/clients/clientMaintain', [
      'historyFilters',
      'paging',
      'clientHistory',
      'summaryFields',
    ]),

    netValue() {
      return this.summaryFields?.netValue?.value
    },

    fieldsNestedTable() {
      return [
        {
          key: 'product',
          label: this.$t('Produto'),
          thStyle: { width: '450px' },
          tdStyle: { width: '450px' },
        },
        {
          key: 'quantity',
          detailKey: 'store.name',
          label: this.$t('Quantidade'),
          tdClass: 'text-center',
        },
        {
          key: 'unitValue',
          detailKey: 'issueDate',
          sortKey: 'issueDate',
          label: this.$t('Valor'),
          filter: 'currency',
          tdClass: 'text-center',
          sortable: true,
        },
        {
          key: 'netValue',
          detailKey: 'netValue',
          sortKey: 'netValue',
          label: this.$t('Total'),
          filter: 'currency',
          tdClass: 'text-right',
          sortable: true,
        },
      ]
    },
  },

  watch: {
    // eslint-disable-next-line func-names
    customerId(val) {
      if (val) {
        this.getData()
      }
    },
  },

  methods: {
    ...mapActions('pages/sale/clients/clientMaintain', [
      'fetchCustomerSaleHistory',
      'resetHistoryFilters',
      'setCurrentPage',
      'setPageSize',
    ]),

    async getData() {
      try {
        this.fetching = true
        await this.fetchCustomerSaleHistory(this.customerId)
      } catch (error) {
        this.showError({ error })
      } finally {
        this.fetching = false
      }
    },

    resetHistoryFiltersLocal() {
      this.resetHistoryFilters()
      this.getData()
    },
    async submitFilter() {
      this.setCurrentPage(1)
      await this.getData()
    },

    pageSizeChange(pageSize) {
      this.setPageSize(pageSize)
      this.getData()
    },

    pageChange(currentPage) {
      this.setCurrentPage(currentPage)
      this.getData()
    },
  },
}
</script>

<style></style>
